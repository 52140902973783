
export function errorHandler(err) {
  let errorCode = '';
  let errorCodeText = '';

  if (err && err.response && err.response.status) {
    errorCode = err.response.status;
    errorCodeText = `${errorCode} Error: `;
  }

  if (
    err
    && err.response
    && err.response.data
    && err.response.data.message
  ) {
    this.$toast.error(`${errorCodeText}${err.response.data.message}`);
  } else if (
    err
    && err.response
    && err.response.data
    && err.response.data.errors
    && err.response.data.errors.length
  ) {
    const { errors } = err.response.data;
    errors.forEach(error => {
      this.$toast.error(error.msg ? `${errorCodeText}${error.msg}` : `${errorCode} Error`);
    });
  } else if (
    err
    && err.response
    && err.response.data
    && typeof err.response.data == 'string'
  ) {
    this.$toast.error(`${errorCodeText}${err.response.data}`);
  } else {
    this.$toast.error(`${errorCode} Error`);
  }
}
