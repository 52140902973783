<template>
  <v-dialog v-model="isLoading" max-width="150">
   <div class="text-center">
      <v-progress-circular :size=70 :width=7 indeterminate color="primary"></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  name: "ProgressSpinner",
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
