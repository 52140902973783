/* eslint-disable no-unused-vars */
// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  items: [
    {
      title: 'Roster',
      icon: 'mdi-account-multiple',
      to: '/roster',
    },
    {
      title: 'Area',
      icon: 'mdi-map-marker',
      to: '/area',
    },
     {
      title: 'Admin',
      icon: 'mdi-shield-crown',
      to: '/admin',
    }

  ],

}

const mutations = make.mutations(state)


const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
