import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import './axios';
import ProgressSpinner from '@/components/Progress'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { errorHandler } from './util/errors';
const options = {
  timeout: 10000,
  closeOnClick: false,
  position: POSITION.TOP_RIGHT
};

Vue.config.productionTip = false
Vue.component('progress-spinner',ProgressSpinner)
Vue.use(Toast, options);
Vue.prototype.$errorHandler = errorHandler;
// Vue.prorotype.$errorHandler = errorHandler;
sync(store, router)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
