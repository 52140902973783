// Imports
import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login.vue'
import {
  layout,
  route,
} from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Login,
      beforeEnter: (to, from, next) => {
        const userToken = localStorage.getItem("accessToken")
        if (userToken) {
          const tokenPayload = parseJwt(userToken);
          if (tokenPayload.exp < Date.now() / 1000) {
            // token expired
            localStorage.removeItem("accessToken");
            localStorage.removeItem("groupOrder");
            next()
          }
          return next({ name: 'Area' })
        } else {
          next()
        }
      }
    },
    layout('Default', [
      // route('Dashboard'),
      route('Roster', null, 'roster'),
      route('Area', null, 'area'),
      route('Admin', null, 'admin'),
      route('Appointment Type', null, 'appointmentType'),
      route('Appointment Details', null, 'appointmentDetails'),
      route('Area Details', null, 'areaDetails')
    ]),
  ],
})

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("accessToken")
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  if (token && authRequired) {
    const tokenPayload = parseJwt(token);
    if (tokenPayload.exp < Date.now() / 1000) {
      // token expired
      localStorage.removeItem("accessToken");
      return next("/");
    }
    next()
  } else if(!token && authRequired){
    return next("/");
  }else {
    next();
  }
})

export default router
