<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height class="pt-15">
        <v-layout  justify-center>
          <v-flex xs12 sm8 md4>
           <v-img
              :src="require('../assets/logo.png')"
              class="my-3"
              contain
              height="200"
            />
            <progress-spinner :isLoading="loading" />
            <v-card class="elevation-12 mt-15">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Shift Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent="getBearerToken">
                  <v-text-field
                    prepend-inner-icon="mdi-account"
                    name="email"
                    label="Email"
                    v-model.trim="email"
                    type="text"
                    required
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    prepend-inner-icon="mdi-lock"
                    name="password"
                    label="Password"
                    v-model="password"
                    type="password"
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <VersionNumber/>
                <v-spacer></v-spacer>
                <v-btn type="submit" color="primary" @click="getBearerToken">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

    </v-main>
  </v-app>
</template>

<script>
const axios = require("axios");
import VersionNumber from '../../src/components/VersonNumber.vue';

export default {
  name: 'Login',
   components: {
    VersionNumber
  },
  data() {
    return {
      email: '',
      password: '',
      processing: false,
      loading:false
    }
  },
  methods: {
    async getBearerToken() {
      this.loading=true
      localStorage.clear();
      await axios.post("auth/login", {
        email: this.email,
        password: this.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.removeItem("accessToken")
          localStorage.setItem("accessToken", response.data.accessToken);
          this.getCurrentUserGroupOrder();
          this.loading=false
          this.$router.push({ name: 'Area' });
        }
      })
      .catch((error) => {
        this.$errorHandler(error);
        this.loading = false;
      }
      );
    },

    async getCurrentUserGroupOrder(){
       await axios.get(`auth/current-user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }).then(response => {
          localStorage.removeItem("groupOrder")
          localStorage.setItem("groupOrder", response.data.UserGroup.Order);
      })
    }
  }
}
</script>

