<template>
  <v-container>
    <v-row>
      <v-input v-model="searchItem"></v-input>
    </v-row>
    <v-list :key="dynamicKey">
      <v-list-item v-for="(item, index) in items" @click="handleSelectItem(item)" :key="index">
        <v-checkbox v-model="item.checked"/>{{item[itemKey]}}
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    value: {
      type: Object,
      default: () => {return {}}
    },
    label: {
      type: String,
      default: 'Select an item'
    },
    items: {
      type: Array,
      default: () => {return []},
    },
    itemKey: {
      type: String,
      default: 'Name'
    }
  },
  data() {
    return {
      searchItem: '',
      selectedItems: [],
      dynamicKey: 0
    }
  },
  methods: {
    handleSelectItem(item) {
      if (item.checked) {
        this.selectedItems = this.selectedItems.filter((filtering) => filtering[this.itemKey] !== item[this.itemKey]);
      } else {
        item.checked = true;
        this.selectedItems.push(item);
      }
      this.dynamicKey += 1;
    }
  },
  mounted() {
    this.items.map((item) => {
      item.checked = false;
    })
  }
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
