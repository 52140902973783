<template>
  <div class="" justify-center>{{ appVersion }}</div>
</template>
<script>
import { version } from '../../package';
export default {
  name: 'VersionNumber',
  computed: {
    appVersion() {
      /* Major Update . Minor Update and New Features . Bug Fixes */
      return `v ${version}`;
    }
  }
}
</script>
